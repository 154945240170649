import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Copyright from "./global-components/copyright";

const ContactV1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        {/* <ContactInfo /> */}
        <ContactForm />
        {/* <Map /> */}
        {/* <CallToActionV1 /> */}
        {/* <Copyright /> */}
    </div>
}

export default ContactV1

